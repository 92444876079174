<template>
    <Section class="bg-white" id="faq">
        <Container class="bg-slate-50">
            <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-4xl font-bold leading-10 tracking-tight text-gray-900">Häufig gestellte Fragen</h2>
                <p class="mt-6 text-base leading-7 text-gray-600">
                    Haben Sie Fragen zu unseren Produkten oder benötigen Sie Unterstützung? Schreiben Sie uns einfach
                    eine
                    <a
                        href="mailto:info@leben-ohne-grenzen.org"
                        class="font-semibold"
                        :class="
                            props.style === 'blue'
                                ? 'text-sky-600 hover:text-sky-500'
                                : 'text-lime-600 hover:text-lime-500'
                        "
                        >E-Mail</a
                    >
                    oder rufen Sie uns an unter
                    <a
                        href="tel:+49 221 828296509"
                        class="font-semibold"
                        :class="
                            props.style === 'blue'
                                ? 'text-sky-600 hover:text-sky-500'
                                : 'text-lime-600 hover:text-lime-500'
                        "
                        >+49 221 82829650</a
                    >.
                </p>
            </div>
            <div class="mt-10 lg:mt-20">
                <dl class="space-y-6 divide-y divide-gray-900/10">
                    <Disclosure as="div" v-for="faq in props.faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
                        <dt>
                            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
                                <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                                <span class="ml-6 flex h-7 items-center">
                                    <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                                    <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                                </span>
                            </DisclosureButton>
                        </dt>
                        <DisclosurePanel as="dd" class="mt-2 pr-12">
                            <p class="text-wrap text-base leading-7 text-gray-600">
                                {{ faq.answer }}
                            </p>
                        </DisclosurePanel>
                    </Disclosure>
                </dl>
            </div>
        </Container>
    </Section>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'
import Section from '@/Components/Fragments/BaseSection.vue'
import Container from '@/Components/Fragments/BaseContainer.vue'

const props = defineProps({
    faqs: {
        type: Array,
        required: true,
    },
    style: {
        type: String,
        default: 'blue',
    },
})
</script>
